import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";

interface BaseLayoutProps {
  height: string;
  showFooterOnMobile?: boolean;
}

export const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  const { children, height, showFooterOnMobile } = props;
  const heights = useMemo(() => {
    if (showFooterOnMobile) {
      return height;
    }

    return { base: "100vh", md: height };
  }, [height, showFooterOnMobile]);

  return (
    <Box as="main" minH={heights} pb="10">
      {children}
    </Box>
  );
};
