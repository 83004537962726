import { useCallback, useEffect } from "react";
import { useRouter } from "blitz";

export const getParam = (a?: string | string[]) => (Array.isArray(a) ? a[0] : a);

export const useUrlTabs = (tabs: string[], baseUrl: string) => {
  const router = useRouter();
  const tab = getParam(router.query["tab"]);
  const index = !tab || tabs.indexOf(tab) === -1 ? 0 : tabs.indexOf(tab);

  const onChange = useCallback(
    (index: number) => {
      router.replace(`${baseUrl}?tab=${tabs[index]}`);
    },
    [baseUrl, router, tabs]
  );

  useEffect(() => {
    if (!tab || tabs.indexOf(tab) === -1) {
      onChange(0);
    }
  }, [tab, onChange, tabs]);

  return [index, onChange] as const;
};
