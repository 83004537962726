import React from "react";
import { BlitzPage, useRouter } from "blitz";
import { Alert, AlertIcon, AlertTitle, CloseButton, useBoolean } from "@chakra-ui/react";
import { LinkButton } from "chakra-next-link";

import { Layout } from "app/core/layouts/Layout";
import { LoginForm } from "app/auth/components/LoginForm";
import { getParam } from "app/core/hooks/useUrlTabs";

const banners: Record<string, string> = {
  ALREADY_ACTIVATED: "Your account is already activated, you can just login now"
};

const LoginPage: BlitzPage = () => {
  const router = useRouter();
  const bannerKey = getParam(router.query.banner);
  const banner = bannerKey && banners[bannerKey];

  const [showBanner, { off }] = useBoolean(true);

  return (
    <>
      {banner && showBanner && (
        <Alert mb={4}>
          <AlertIcon />
          <AlertTitle>{banner}</AlertTitle>
          <CloseButton position="absolute" right="8px" top="8px" onClick={off} />
        </Alert>
      )}

      <LoginForm
        buttons={<LinkButton href="/setup-account">Join</LinkButton>}
        onSuccess={() => {
          const next = (router.query.next as string) ?? "/";
          router.push(next);
        }}
      />
    </>
  );
};

LoginPage.redirectAuthenticatedTo = "/";
LoginPage.getLayout = (page) => <Layout title="Log In">{page}</Layout>;

export default LoginPage;
