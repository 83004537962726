import React, { ReactNode } from "react";
import { Head, useSession } from "blitz";

import { BaseLayout } from "./BaseLayout";

type LayoutProps = {
  title?: string;
  children: ReactNode;
};

export const useNavHeight = () => {
  const session = useSession();
  return !!session.impersonatingFromUserId ? 80 + 64 : 80;
};

export const Layout = ({ title, children }: LayoutProps) => {
  const height = useNavHeight();

  return (
    <>
      <Head>
        <title>{title || "med-app"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <BaseLayout height={`calc(100vh - ${height})`}>{children}</BaseLayout>
    </>
  );
};
